@use "global-color" as *;

@mixin semantic-light-tokens {
    --s-color-text-primary: #{$g-color-neutral-900};
    --s-color-text-secondary: #{$g-color-neutral-600};
    --s-color-text-quiet: #{$g-color-neutral-500};
    --s-color-text-inverse: #{$g-color-neutral-10};

    --s-color-icon-primary: #{$g-color-neutral-900};
    --s-color-icon-secondary: #{$g-color-neutral-600};
    --s-color-icon-quiet: #{$g-color-neutral-500};
    --s-color-icon-inverse: #{$g-color-neutral-10};

    --s-color-surface-primary: #{$g-color-neutral-warm-50};
    --s-color-surface-secondary: #{$g-color-neutral-warm-10};
    --s-color-surface-quiet: #{$g-color-neutral-warm-100};

    --s-color-separator-standard: #{$g-color-neutral-200};
    --s-color-separator-bold: #{$g-color-neutral-900};

    --s-color-alert-standard: #{$g-color-red-500};
    --s-color-alert-background: #{$g-color-red-50};
    --s-color-alert-foreground: #{$g-color-red-500};
    --s-color-alert-border: #{$g-color-red-200};

    --s-color-info-standard: #{$g-color-blue-500};
    --s-color-info-background: #{$g-color-blue-50};
    --s-color-info-foreground: #{$g-color-blue-600};
    --s-color-info-border: #{$g-color-blue-200};

    --s-color-success-standard: #{$g-color-green-500};
    --s-color-success-background: #{$g-color-green-50};
    --s-color-success-foreground: #{$g-color-green-600};
    --s-color-success-border: #{$g-color-green-200};

    --s-color-field-border-default: #{$g-color-neutral-400};
    --s-color-field-border-active: #{$g-color-neutral-900};
    --s-color-field-border-focus: #{$g-color-neutral-900};
    --s-color-field-border-error: var(--s-color-alert-standard);

    --s-color-selection-background-default: var(--s-color-surface-secondary);
    --s-color-selection-background-active: #{$g-color-primary-50};
    --s-color-selection-background-selected-bold: #{$g-color-primary-900};
    --s-color-selection-background-selected-quiet: var(
        --s-color-surface-secondary
    );

    --s-color-selection-foreground-default: #{$g-color-primary-900};
    --s-color-selection-foreground-active: #{$g-color-primary-600};
    --s-color-selection-foreground-selected-bold: #{$g-color-primary-10};
    --s-color-selection-foreground-selected-quiet: #{$g-color-primary-600};

    --s-color-selection-border-focus: #{$g-color-primary-500};
    --s-color-selection-border-selected-bold: #{$g-color-primary-900};
    --s-color-selection-border-selected-quiet: #{$g-color-primary-500};

    --s-color-tab-background-default: var(--s-color-surface-quiet);
    --s-color-tab-background-active: var(--s-color-surface-secondary);
    --s-color-tab-background-selected: var(--s-color-surface-secondary);

    --s-color-tab-foreground-default: var(--s-color-text-secondary);
    --s-color-tab-foreground-active: #{$g-color-primary-500};
    --s-color-tab-foreground-selected: #{$g-color-primary-900};

    --s-color-tab-border-focus: #{$g-color-primary-500};
    --s-color-tab-border-selected: #{$g-color-primary-900};

    --s-color-scrollbar-default: #{$g-color-neutral-400};

    --s-color-neutral-standard: #{$g-color-neutral-600};
    --s-color-neutral-background: #{$g-color-neutral-100};
    --s-color-neutral-foreground: #{$g-color-neutral-600};
    --s-color-neutral-border: #{$g-color-neutral-300};

    --s-color-disabled-primary: #{$g-color-neutral-400};
    --s-color-disabled-secondary: #{$g-color-neutral-10};

    @media (forced-colors: active) {
        --s-color-disabled-primary: GrayText;
        --s-color-disabled-secondary: GrayText;
    }

    --s-color-dataviz-primary: #{$g-color-primary-600};
    --s-color-dataviz-secondary: #{$g-color-teal-500};

    --s-color-promo1-background: #{$g-color-teal-100};
    --s-color-promo1-text-large: #{$g-color-neutral-900};
    --s-color-promo1-text-standard: #{$g-color-neutral-900};
    --s-color-promo1-accent-background: #{$g-color-purple-500};
    --s-color-promo1-accent-foreground: #{$g-color-neutral-10};

    --s-color-promo2-background: #{$g-color-yellow-200};
    --s-color-promo2-text-large: #{$g-color-neutral-900};
    --s-color-promo2-text-standard: #{$g-color-neutral-900};
    --s-color-promo2-accent-background: #{$g-color-pink-500};
    --s-color-promo2-accent-foreground: #{$g-color-neutral-10};

    --s-color-promo3-background: #{$g-color-pink-400};
    --s-color-promo3-text-large: #{$g-color-neutral-900};
    --s-color-promo3-text-standard: #{$g-color-neutral-900};
    --s-color-promo3-accent-background: #{$g-color-yellow-200};
    --s-color-promo3-accent-foreground: #{$g-color-neutral-900};

    --s-color-promo4-background: #{$g-color-green-100};
    --s-color-promo4-text-large: #{$g-color-neutral-900};
    --s-color-promo4-text-standard: #{$g-color-neutral-900};
    --s-color-promo4-accent-background: #{$g-color-purple-500};
    --s-color-promo4-accent-foreground: #{$g-color-neutral-10};

    --s-color-promo-price-standard: #{$g-color-green-500};
    --s-color-promo-deal-standard: #{$g-color-green-500};
    --s-color-promo-deal-background: #{$g-color-green-500};
    --s-color-promo-deal-foreground: #{$g-color-green-10};
    --s-color-promo-deal-border: #{$g-color-green-400};

    --s-color-accent1-standard: #{$g-color-pink-500};
    --s-color-accent1-bold: #{$g-color-neutral-warm-700};
    --s-color-accent1-quiet: #{$g-color-neutral-warm-50};

    --s-color-accent2-standard: #{$g-color-teal-500};
    --s-color-accent2-bold: #{$g-color-neutral-warm-700};
    --s-color-accent2-quiet: #{$g-color-neutral-warm-50};

    --s-color-accent3-standard: #{$g-color-green-500};
    --s-color-accent3-bold: #{$g-color-neutral-warm-700};
    --s-color-accent3-quiet: #{$g-color-neutral-warm-50};

    --s-color-accent4-standard: #{$g-color-purple-500};
    --s-color-accent4-bold: #{$g-color-neutral-warm-700};
    --s-color-accent4-quiet: #{$g-color-neutral-warm-50};

    --s-color-brand-primary-standard: #{$g-color-primary-500};
    --s-color-brand-primary-bold: #{$g-color-neutral-warm-700};
    --s-color-brand-primary-quiet: #{$g-color-neutral-warm-50};

    --s-color-brand-secondary-standard: #{$g-color-secondary-900};
    --s-color-brand-secondary-bold: #{$g-color-secondary-900};
    --s-color-brand-secondary-quiet: #{$g-color-neutral-100};

    --s-gradient-accent1-quiet: linear-gradient(
        180deg,
        #{rgba($g-color-neutral-warm-50, 1)} 0%,
        #{rgba($g-color-neutral-warm-50, 1)} 100%
    );
    --s-gradient-accent2-quiet: linear-gradient(
        180deg,
        #{rgba($g-color-neutral-100, 1)} 0%,
        #{rgba($g-color-neutral-100, 1)} 100%
    );
    --s-gradient-accent3-quiet: linear-gradient(
        180deg,
        #{rgba($g-color-neutral-warm-50, 1)} 0%,
        #{rgba($g-color-neutral-warm-50, 1)} 100%
    );
    --s-gradient-accent4-quiet: linear-gradient(
        180deg,
        #{rgba($g-color-neutral-warm-50, 1)} 0%,
        #{rgba($g-color-neutral-warm-50, 1)} 100%
    );
    --s-gradient-accent-surface-quiet: linear-gradient(
        180deg,
        #{rgba($g-color-neutral-warm-50, 1)} 0%,
        #{rgba($g-color-neutral-warm-50, 1)} 100%
    );

    --s-gradient-dataviz-primary: linear-gradient(
        180deg,
        #{$g-color-primary-300} 0%,
        #{$g-color-primary-500} 100%
    );
    --s-gradient-dataviz-secondary: linear-gradient(
        180deg,
        #{$g-color-teal-300} 0%,
        #{$g-color-teal-500} 100%
    );
    --s-gradient-dataviz-primary-start: #{$g-color-primary-300};
    --s-gradient-dataviz-primary-end: #{$g-color-primary-500};
    --s-gradient-dataviz-secondary-start: #{$g-color-teal-300};
    --s-gradient-dataviz-secondary-end: #{$g-color-teal-500};

    --s-shadow-standard-default: 0px 0px 0px 2px #{rgba($g-color-neutral-900, 1)};
    --s-shadow-standard-active: 0px 0px 0px 2px #{rgba($g-color-black, 1)};
    --s-shadow-up: 0px -1px 1px 0px #{rgba($g-color-neutral-200, 1)};
    --s-shadow-down: 0px 1px 1px 0px #{rgba($g-color-neutral-200, 1)};
}
